import React, {useRef, useEffect, createRef} from "react";
import { Seo } from "../../components/Seo";
import {graphql} from 'gatsby';
import Carousel from "../../components/Projects/Carousel";
import { StaticImage } from "gatsby-plugin-image";
import ContactForm from "../../components/ContactForm";

const TanzeeProject = ({data}) => {
  const textRef = useRef([]);
  const imageRef = useRef([]);
  for(let i = 0; i < 3; i++) {
    textRef.current[i] = createRef();
    imageRef.current[i] = createRef();
  }

  const resizeSection = () => {
    for(const[k,i] of Object.entries(textRef.current)) {
      if(i.current || false) {
        let mheight = i.current.offsetHeight + 40;
        
        imageRef.current[k].current.style.maxHeight = `${mheight}px`;
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', resizeSection);
    resizeSection();
  }, []);

  const projects = data.allPrismicMixedContentProject.edges.map((i,k) => ({
    to: i.node.uid,
    name: i.node.data.title,
    description: i.node.data.description,
    src: i.node.data.cover_image.url
  }))
  return (
    <main>
      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div ref={textRef.current[0]}
            className="py-24 px-16 xl:px-32">
            <h1 className="font-black text-4xl lg:text-5xl mb-12">
              Tanzee.<br />
              Home of the self tan bed-sheet protector.
            </h1>
            <p className="text-xl lg:text-2xl mb-8">
              Tanzee is a fast scaling eCommerce brand that is taking the world by storm one tanning routine product at a time. After starting out with the OG self-tan bed sheet protector, Tanzee has evolved into a global beauty business that is constantly pushing the boundaries to provide luxurious beauty products that can be used by anyone who loves a glowing tan - but hates the messy bedsheets and streaky skin!
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Tanzee's brand is rock-solid, now their supply chain is too.
            </p>
            <p className="font-bold text-xl lg:text-2xl">Here's how we made amazing happen for Tanzee.</p>
          </div>
          <div className="flex h-full"
            ref={imageRef.current[0]}>
              <StaticImage src="../../images/tanzee-1.jpg"
                alt=""
                className="object-cover min-w-[100%]" />
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-24">
        <div className="xl:container grid-cols-1 lg:grid-cols-3 mx-auto px-6 grid text-center gap-x-12 gap-y-12">
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>$40,000+</span>
            </p>
            <p className="font-black text-xl">ORDER VALUE</p>
          </div>
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>1 Product,<br />4 SKUs</span>
            </p>
            <p className="font-black text-xl">PRODUCT RANGE</p>
          </div>
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>+45 Days</span>
            </p>
            <p className="font-black text-xl">IMPROVED LEAD TIME</p>
          </div>
        </div>
      </section>

      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div className="flex h-full"
            ref={imageRef.current[1]}>
              <StaticImage src="../../images/tanzee-2-2175w.jpg"
                alt=""
                className="object-cover min-w-[100%]" />
          </div>
          <div ref={textRef.current[1]}
            className="py-24 px-16 xl:px-32">
            <h3 className="font-black text-2xl lg:text-3xl mb-12">
              Need For Speed.
            </h3>
            <p className="text-xl lg:text-2xl mb-8">
              Tanzee experienced rapid year on year growth with expansion plans into the US, but their existing supplier located in Vietnam just couldn't keep up with the rolling orders.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              This made Madi, Tanzees founder question if her supply-chain was set up to really allow her to scale with confidence and ensure there were no more delays for her orders.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Factories that struggle to keep up with demand can really damage the ability for fast-growing e-commerce brands to scale. Its just as bad for a brand to be constantly stuck on pre-sale while waiting for the latest batch of inventory to be delivered.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
            After months of reviewing her options, Madi made the decision to move away from her existing Vietnamese supplier and move forward with Sourci where she knew her lead time would be reduced, she would be saving on her costs and have complete confidence to scale the Tanzee brand in the other countries.
            </p>
            <p className="font-bold text-2xl lg:text-3xl">Enter Sourci.</p>
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-24">
        <div className="xl:container mx-auto px-6 text-center">
          <h3 className="font-black text-5xl lg:text-6xl">
            Sourci puts product sourcing wizardry at the heart of your team. Giving total control and support, so those uncertainties just disappear.
            <span className="block text-sourci-pink">You got this.</span>
          </h3>
        </div>
      </section>

      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div ref={textRef.current[2]}
            className="py-24 px-16 xl:px-32">
            <h3 className="font-black text-2xl lg:text-3xl mb-12">
              How Our Product Sourcing Wizardry Cut Tanzee's Lead Time in Half.
            </h3>
            <p className="text-xl lg:text-2xl mb-8">
              In just 2 months, Sourci was able to work with the Tanzee team on making improvements to her existing products, whilst providing a more competitive price with a lead time that was almost halved.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Madi had access to her very own bolt-on sourcing, development, procurement, quality control, production management, and freight & logistics team that got to work to make amazing happen!
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              We were able to find Madi a factory that could meet the demands of her scaling brand whilst ensuring a high level of quality and a reasonable price- what a win!
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              We are now working with Madi to oversee her entire production and supply chain and are expanding our offering to start working on her full range of products including her self tan bedsheet protector, pillowcase and others.
            </p>
          </div>
          <div className="flex h-full"
            ref={imageRef.current[2]}>
              <StaticImage src="../../images/tanzee-3-2175w.jpg"
                alt=""
                className="object-cover min-w-[100%]" />
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-32">
        <div className="xl:container lg:grid lg:grid-cols-2 mx-auto px-6">
          <div className="text-black">
            <p className="text-4xl md:text-6xl font-black mb-8">The time<br /> is now.</p>
            <p className="text-black text-4xl mb-8 font-black">Let's make amazing<br /> happen today.</p>
            <p className="text-3xl mb-2">
              <a href="tel:+611300768724"
                className="hover:underline">1300 SOURCI</a>
            </p>
            <p className="text-3xl mb-2">
              <a href="mailto:hello@sourci.com.au"
                className="hover:underline">hello@sourci.com.au</a>
            </p>
          </div>
          <div className="mt-12 lg:mt-0">
            <ContactForm bg="light"
              location="Case Study - Tanzee" />
          </div>
        </div>
      </section>
      <Carousel projects={projects}
        project="tanzee"
        title="Check out our other Projects" />
    </main>
  )
}

export const query = graphql`
  query {
    allPrismicMixedContentProject {
      edges {
        node {
          data {
            title
            description
            cover_image {
              url
            }
          }
          uid
        }
      }
    }
  }
` 

export default TanzeeProject;

export function Head() {
  return (
    <Seo title="Tanzee | Our Projects | Sourci"
      description="Tanzee is a scaling eCommerce brand that is taking the world by storm one tanning routine product at a time."
      canonical="/projects/tanzee" />
  )
}